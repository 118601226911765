import React, {ReactElement} from 'react';
import { useParams } from 'react-router';

const Position = ():ReactElement => {
    const { id } = useParams();
    return(
        <>
            <h1>
               Position 
            </h1>
            <h2>
                {id}
            </h2>
        </>
    );
};

export default Position;
