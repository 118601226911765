import React, {ReactElement} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton, Menu, MenuItem, } from '@mui/material';
import { Logout, Menu as MenuIcon, Person, PersonAdd, Search, Settings } from '@mui/icons-material';
import { NavbarProps } from '.';
import CompanyName from './CompanyName';
import { Box } from '@mui/system';

const MobileNav = ({ loggedIn, handleLogout } : NavbarProps) : ReactElement => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchorEl);
    const handleIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    return(
        <>
            <CompanyName />
            <Box sx={{flexGrow: 1}} />
            <IconButton onClick={handleIconClick}>
                <MenuIcon />
            </IconButton>
            <Menu 
                open={isOpen}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                onClick={handleMenuClose}>
                <MenuItem component={RouterLink} to="/search"><Search sx={{marginRight: 1}} />Find Internships</MenuItem>
                {!loggedIn && <MenuItem component={RouterLink} to="/users/login"><Person sx={{marginRight: 1}} />Login</MenuItem>}
                {!loggedIn && <MenuItem component={RouterLink} to="/users/signup"><PersonAdd sx={{marginRight: 1}} />Get Started</MenuItem>}
                {loggedIn && <MenuItem component={RouterLink} to="/users/profile"><Person sx={{marginRight: 1}} />Profile</MenuItem>}
                {loggedIn && <MenuItem component={RouterLink} to="/users/settings"><Settings sx={{marginRight: 1}} />Settings</MenuItem>}
                {loggedIn && <MenuItem onClick={handleLogout}><Logout sx={{marginRight: 1}} />Logout</MenuItem>}
            </Menu>
        </>
    );
};

export default MobileNav;
