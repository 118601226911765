import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import {
    Alert,
    Button,
    FormControl,
    Input,
    InputLabel,
    Link,
    Typography,
} from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { sendUserPasswordResetEmail } from '../../services/auth';

const FormContainer = styled.div`
    margin: 0 auto;
    max-width: 350px;
    padding: 30px;
    text-align: center;
`;

const FormHeader = styled.div`
    text-align: left;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
    margin-top: 30px;
`;

const Container = styled.div`
    text-align: center;
`;

const ForgotPassword = (): ReactElement => {
    const [email, setEmail] = React.useState('');
    const [success, setSuccess] = React.useState(false);
    const handleEmailChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        setEmail(event.target.value);
    };
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        sendUserPasswordResetEmail(email)
            .then(() => {
                setSuccess(true);
            })
            .catch((errorMsg) => {
                setSuccess(true);
            });
    };
    return (
        <Container>
            {success && (
                <Alert icon={<CheckCircle />}>
                    <Typography variant="body1">
                        If an account with the provided email address exists, an
                        email will be sent to the address with instructions on how
                        to reset your password.
                    </Typography>
                </Alert>
            )}
            <FormContainer>
                <FormHeader>
                    <Typography variant="h5" component="h1">
                        Reset Your Password
                    </Typography>
                </FormHeader>
                <Form onSubmit={handleSubmit}>
                    <FormControl
                        margin="normal"
                        variant="standard"
                    >
                        <InputLabel htmlFor="email-input">Email</InputLabel>
                        <Input
                            id="email-input"
                            value={email}
                            type="email"
                            onChange={handleEmailChange}
                            required
                        />
                    </FormControl>
                    <ButtonContainer>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Send reset link
                        </Button>
                    </ButtonContainer>
                    <ButtonContainer>
                        <Link
                            component={RouterLink}
                            to="/users/login"
                            underline="hover"
                        >
                            Return to sign in
                        </Link>
                    </ButtonContainer>
                </Form>
            </FormContainer>
        </Container>
    );
};

export default ForgotPassword;
