import React, {ReactElement} from 'react';

const Settings = ():ReactElement => {
    return(
        <>
            <h1>
               Settings 
            </h1>
        </>
    );
};

export default Settings;
