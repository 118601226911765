import React, {ReactElement} from 'react';
import styled from 'styled-components';
import logo from '../../logo.svg';

import PreviewPosition, {PreviewPositionProps} from "../../components/basic/PreviewPosition";
import SearchContainer from "./SearchContainer";

// styles

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  row-gap: 2.5rem;
`;

const CompanyListing = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  row-gap: 2rem;
`;

// component

const Search = ():ReactElement => {

    // example
    let company = 'Snapchat';
    let description = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.';
    let img = logo;
    let position = 'SWE Intern'
    let scoreDen = 5;
    let scoreNum = 4.7;

    let previewPositionProps: PreviewPositionProps = {
        company: company,
        description: description,
        img: img,
        link: '/company/snapchat-link',
        position: position,
        scoreDen: scoreDen,
        scoreNum: scoreNum,
    }
    return(
        <Container>
            <SearchContainer />
            <CompanyListing>
                <PreviewPosition {...previewPositionProps} />
                <PreviewPosition {...previewPositionProps} />
                <PreviewPosition {...previewPositionProps} />
                <PreviewPosition {...previewPositionProps} />
                <PreviewPosition {...previewPositionProps} />
                <PreviewPosition {...previewPositionProps} />
            </CompanyListing>
        </Container>
    );
};

export default Search;
