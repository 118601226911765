import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyAGMMEbU7gMzy6YVAvjmxgos9ddxK4KiwU",
    authDomain: "internshipped.firebaseapp.com",
    projectId: "internshipped",
    storageBucket: "internshipped.appspot.com",
    messagingSenderId: "1045793769926",
    appId: "1:1045793769926:web:2829d0a864bc160c280ac7"
};

const app = initializeApp(firebaseConfig);
export default app;
