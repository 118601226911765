import './App.css';
import Home from './screens/Home';
import Login from './screens/Login';
import Signup from './screens/Signup';
import ForgotPassword from './screens/ForgotPassword';
import Company from './screens/Company';
import Position from './screens/Position';
import Profile from './screens/Profile';
import Search from './screens/Search';
import { Routes, Route } from "react-router-dom";
import Navbar from './components/Navbar';
import Settings from './screens/Settings';
import { signOutUser, onIdTokenChangedListener } from './services/auth';
import { useEffect, useState } from 'react';

function App() {
  const [user, setUser] = useState(null);
  const [fullName, setFullName] = useState('');
  const handleLogout = () : void => {
    signOutUser();
  };
  useEffect(() => {
    const unsubscribe = onIdTokenChangedListener((user) => {
      setUser(user);
      if (user) {
        setFullName(user.displayName);
      }
    });
    return () => unsubscribe();
  }, []);
  return (
    <>
      <Navbar loggedIn={user !== null} fullName={fullName} handleLogout={handleLogout} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/company/:company" element={<Company />}/>
        <Route path="/position/:id" element={<Position />}/>
        <Route path="/users/login" element={<Login />} />
        <Route path="/users/signup" element={<Signup />} />
        <Route path="/users/login/forgot" element={<ForgotPassword />} />
        <Route path="/users/profile" element={<Profile />} />
        <Route path="/users/profile/:uid" element={<Profile />} />
        <Route path="/users/settings" element={<Settings />} />
        <Route path="/search" element={<Search />} />
      </Routes>
    </>
  );
}

export default App;
