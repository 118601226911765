import {
    createUserWithEmailAndPassword,
    getAuth,
    onAuthStateChanged,
    onIdTokenChanged,
    reload,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
    updateProfile,
} from 'firebase/auth';
import app from './app';

const auth = getAuth(app);

const errorCodeToMessage = (errorCode: string) => {
    switch (errorCode) {
        case 'auth/email-already-in-use':
            return 'This email address is already in use.';
        case 'auth/too-many-requests':
            return 'You have submitted too many requests. Please wait before trying again.';
        case 'auth/network-request-failed':
            return 'A network error has occurred. Please try again later.';
        case 'auth/user-disabled':
            return 'Your account has been disabled. Please contact support.';
        case 'auth/invalid-email':
            return 'The provided email address is invalid.';
        case 'auth/weak-password':
            return 'The provided password is too weak.';
        case 'auth/user-not-found':
            return 'Invalid email or password.';
        case 'auth/wrong-password':
            return 'Invalid email or password.';
        default:
            return 'An unknown error has occurred. Please try again later.';
    }
};

const updateDisplayNameForUser = async (user: any, name: string) => {
    return updateProfile(user, { displayName: name })
        .then(() => {
            return reload(user)
                .then(() => {
                    return Promise.resolve();
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const signUpUser = async (
    name: string,
    email: string,
    password: string
) => {
    return createUserWithEmailAndPassword(auth, email, password)
        .then(() => {
            const user = auth.currentUser;
            updateDisplayNameForUser(user, name)
                .then(() => {
                    return Promise.resolve();
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        })
        .catch((error) => {
            const errorCode = error.code as string;
            const errorMsg = errorCodeToMessage(errorCode);
            return Promise.reject(errorMsg);
        });
};

export const signInUser = async (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password)
        .then(() => {
            return Promise.resolve();
        })
        .catch((error) => {
            const errorCode = error.code as string;
            const errorMsg = errorCodeToMessage(errorCode);
            return Promise.reject(errorMsg);
        });
};

export const signOutUser = async () => {
    return signOut(auth)
        .then(() => {
            return Promise.resolve();
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const sendUserPasswordResetEmail = async (email: string) => {
    return sendPasswordResetEmail(auth, email)
        .then(() => {
            return Promise.resolve();
        })
        .catch((error) => {
            const errorCode = error.code as string;
            const errorMsg = errorCodeToMessage(errorCode);
            return Promise.reject(errorMsg);
        });
};

export const getCurrentUser = () => {
    return auth.currentUser;
};

export const updateUserDisplayName = async (name: string) => {
    const user = auth.currentUser;
    if (user) {
        return updateDisplayNameForUser(user, name);
    }
    return Promise.reject('No user is signed in.');
};

export const onAuthStateChangedListener = (callback: (user: any) => void) => {
    return onAuthStateChanged(auth, callback);
};

export const onIdTokenChangedListener = (callback: (user: any) => void) => {
    return onIdTokenChanged(auth, callback);
};
