import React, {ReactElement} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { Box } from '@mui/system';

const Container = muiStyled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
});

const NoBookmarks = ():ReactElement => {
    return(
        <Container>
            <Typography variant="body1" component="p" sx={{textAlign: 'center'}}>You haven't bookmarked anything yet</Typography>
            <Typography variant="body2" color="GrayText" component="p" sx={{textAlign: 'center'}}>Bookmarks allow you to keep track of internships that interest you</Typography>
            <Link component={RouterLink} to="/search" variant="body1" underline="hover">Find an Internship</Link>
        </Container>
    );
};

export default NoBookmarks;
