import React, {ReactElement} from 'react';

const Home = ():ReactElement => {
    return(
        <>
            <h1>
                Home
            </h1>
        </>
    );
};

export default Home;
