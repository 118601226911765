import React, {ReactElement} from 'react';
import { Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import BookmarksTab from './BookmarksTab';
import ReviewsTab from './ReviewsTab';

const ProfileTabs = ():ReactElement => {
    const [currentTab, setCurrentTab] = React.useState(0);
    return(
        <Box sx={{width: '100%'}}>
            <Box>
                <Tabs
                    value={currentTab} 
                    indicatorColor="primary" 
                    textColor="primary" 
                    onChange={(e, i) => setCurrentTab(i)}
                    centered>
                    <Tab label="Bookmarks" />
                    <Tab label="Reviews" />
                </Tabs>
            </Box>
            {currentTab === 0 && <BookmarksTab />}
            {currentTab === 1 && <ReviewsTab />}
        </Box>
    );
};

export default ProfileTabs;
