import React, {ReactElement} from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";

// Styling

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
`;

const SearchFieldTop = styled(TextField)`
  & .MuiOutlinedInput-root {
    border-radius: .5rem .5rem 0 0;
  }
`;

const SearchFieldBottom = styled(TextField)`
  & .MuiOutlinedInput-root {
    border-radius: 0 0 .5rem .5rem;
  }
`;

// Component

const SearchContainer = (): ReactElement => {
    return (
        <Container>
            <SearchFieldTop
                id="textfield-search"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <label htmlFor="textfield-search">
                                <SearchIcon />
                            </label>
                        </InputAdornment>
                    ),
                }}
                placeholder="Search"
                size="small"
                variant="outlined"
            />
            <SearchFieldBottom
                id="textfield-location"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <label htmlFor="textfield-location">
                                <RoomOutlinedIcon />
                            </label>
                        </InputAdornment>
                    ),
                }}
                placeholder="Location"
                size="small"
                variant="outlined"
            />
        </Container>
    );
};

export default SearchContainer;
