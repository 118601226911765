// This file contains a list of constants representing different media queries

// Media Query strings

const maxWidthMediaQuery = (screenMaxWidth: string) => {
    return "(max-width:" + screenMaxWidth + ")";
}


// Screen dimensions

const mobileMaxWidth = "600px";

export {
    mobileMaxWidth,
    maxWidthMediaQuery,
}
