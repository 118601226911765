import React, {ReactElement} from 'react';
import styled from 'styled-components';
import NoReviews from './NoReviews';

const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    row-gap: 2.5rem;
`;

const Reviews = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 500px;
    row-gap: 2rem;
    margin: auto;
`;

const ReviewsTab = ():ReactElement => {
    return(
        <Container>
            <Reviews>
                <NoReviews />
            </Reviews>
        </Container>
    );
};

export default ReviewsTab;
