import React, {ReactElement} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Stack, } from '@mui/material';


const UnAuthedDesktopComponents = () : ReactElement => {
    return(
        <Stack direction="row" spacing={2} sx={{marginLeft: 1}}>
            <Button color="inherit" component={RouterLink} to="/users/login">Login</Button>
            <Button color="primary" component={RouterLink} variant="contained" to="/users/signup">Get Started</Button>
        </Stack>
    );
};

export default UnAuthedDesktopComponents;
