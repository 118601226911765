import React, { ReactElement, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { Container } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import ProfileTabs from './ProfileTabs';
import ProfileInfo from './ProfileInfo';
import { getCurrentUser } from '../../services/auth';

const ProfileContainer = muiStyled(Container)({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1rem',
});

const Profile = (): ReactElement => {
    const { uid } = useParams();
    const navigate = useNavigate();
    const user = getCurrentUser();
    const userId = uid || user?.displayName || '';
    useEffect(() => {
        if (!user) {
            navigate('/');
        }
    });
    return (
        <ProfileContainer>
            <ProfileInfo fullName={userId} />
            <ProfileTabs />
        </ProfileContainer>
    );
};

export default Profile;
