import React, {ReactElement} from 'react';
import styled from 'styled-components';
import NoBookmarks from './NoBookmarks';

const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    row-gap: 2.5rem;
`;

const Bookmarks = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 500px;
    row-gap: 2rem;
    margin: auto;
`;

const BookmarksTab = ():ReactElement => {
    return(
        <Container>
            <Bookmarks>
                <NoBookmarks />
            </Bookmarks>
        </Container>
    );
};

export default BookmarksTab;
