import React, {ReactElement} from 'react';
import styled from 'styled-components';

// Styles

const Container = styled.div`
  text-align: center;
`;

const Denominator = styled.span`
  font-size: 1rem;
`;

const NumeratorContainer = styled.span`
  font-weight: 500;
`;

const NumeratorBig = styled(NumeratorContainer)`
  font-size: 3rem;
`;

const NumeratorSmall = styled(NumeratorContainer)`
  font-size: 1.25rem;
`;

// Components

export declare interface ScoreProps {
    className?: string,
    denominator: number,
    isSmall?: boolean,
    numerator: number,
};

const Score = ({className, denominator, isSmall = true, numerator} : ScoreProps):ReactElement => {
    const Numerator = isSmall? NumeratorSmall : NumeratorBig;
    return(
        <Container className={className}>
            <Numerator>{(numerator).toFixed(1)}</Numerator>
            <Denominator>/{(denominator).toFixed(0)}</Denominator>
        </Container>
    );
};

export default Score;
