import React, {ReactElement, useState} from 'react';
import styled from 'styled-components';

import { Link as RouterLink } from "react-router-dom";

import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkFilledIcon from '@mui/icons-material/Bookmark';
import Score from './Score';

// styles

const CompanyName = styled.h2`
  margin: 0;
`;

const PositionName = styled.h3`
  font-weight: 500;
  margin: 0;
;`

const Container = styled(RouterLink)`
  border-radius: .75rem;
  box-shadow: 0px 2px 10px lightgrey;
  color: inherit;
  cursor: pointer;
  display: flex;
  filter: drop-shadow();
  flex-direction: row;
  padding: .5rem;
  text-decoration: inherit;

  &:link {
    ${PositionName} {
      color: #454545;
    }
  }
`;

const Icon = styled.img`
  height: 50px;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PositionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  row-gap: 0.25rem;
  text-align: left;
`;

const PositionDescription = styled.p`
  margin: 0;
`;

const BookmarkStyledBorderOutlineIcon = styled(BookmarkBorderOutlinedIcon)`
  color: grey;
`;

const BookmarkStyledFilledIcon = styled(BookmarkFilledIcon)`
  color: grey;
`;

// component

export declare interface PreviewPositionProps {
    className?: string,
    company: string,
    description: string,
    img: string,
    link: string,
    position: string,
    scoreDen: number,
    scoreNum: number,
}

const PreviewPosition = ({
    className,
    company,
    description,
    img,
    link,
    position,
    scoreDen,
    scoreNum,
}: PreviewPositionProps): ReactElement => {
    const [isBookmarked, setIsBookmarked] = useState(false);

    const bookmarkOnClick = (event: React.MouseEvent<SVGSVGElement>) => {
        // stops click from triggering Container's onClick function
        event.stopPropagation();
        event.preventDefault();

        setIsBookmarked(!isBookmarked);
    }

    const BookmarkIcon = isBookmarked ? BookmarkStyledFilledIcon : BookmarkStyledBorderOutlineIcon;

    return (
        <Container className={className} to={link} >
            <LeftContainer>
                <Icon src={img} />
                <Score denominator={scoreDen} isSmall={true} numerator={scoreNum} />
            </LeftContainer>
            <PositionContainer>
                <CompanyName>{company}</CompanyName>
                <PositionName>{position}</PositionName>
                <PositionDescription>{description}</PositionDescription>
            </PositionContainer>
            <BookmarkIcon onClick={bookmarkOnClick}/>
        </Container>
    );
};

export default PreviewPosition;
