import React, {ReactElement} from 'react';
import { useParams } from 'react-router';

const Company = ():ReactElement => {
    const { company } = useParams();
    return(
        <>
            <h1>
               Company
            </h1>
            <h2>
                {company}
            </h2>
        </>
    );
};

export default Company;
