import React, { ReactElement } from 'react';
import { Avatar } from '@mui/material';

export declare interface UserAvatarProps {
    fullName: string;
}

const nameToInitials = (fullName: string): string => {
    if (!fullName) return '';
    const nameParts = fullName.split(' ').filter((part) => part.length > 0);
    if (nameParts.length === 0) {
        return '';
    }
    return nameParts.length > 1
        ? `${nameParts[0].charAt(0)}${nameParts[nameParts.length - 1].charAt(
              0
          )}`
        : nameParts[0].charAt(0);
};

const UserAvatar = ({ fullName }: UserAvatarProps): ReactElement => {
    const initials = nameToInitials(fullName);
    return <Avatar sx={{ backgroundColor: 'forestgreen' }}>{initials}</Avatar>;
};

export default UserAvatar;
