import React, {ReactElement} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Divider, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { NavbarProps } from '.';
import CompanyName from './CompanyName';
import AuthedDesktopComponents from './AuthedDesktopComponents';
import UnAuthedDesktopComponents from './UnAuthedDesktopComponents';

const NavComponents = () : ReactElement => {
    return(
        <Stack direction="row" spacing={2} sx={{marginRight: 1}}>
            <Button color="inherit" component={RouterLink} to="/search">Find Internships</Button>
        </Stack>
    );
};

const DesktopNav = ({ loggedIn, fullName="", handleLogout } : NavbarProps) : ReactElement => {
    return(
        <>
            <CompanyName />
            <Box sx={{flexGrow: 1}} />
            <NavComponents />
            <Divider variant="middle" orientation="vertical" flexItem />
            {loggedIn ? <AuthedDesktopComponents fullName={fullName} handleLogout={handleLogout} />  : <UnAuthedDesktopComponents />}
        </>
                
    );
};

export default DesktopNav;
