import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import {
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Link,
    Tooltip,
    Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const FormContainer = styled.div`
    margin: 0 auto;
    max-width: 350px;
    padding: 30px;
    text-align: center;
`;

const FormHeader = styled.div`
    text-align: left;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const FormButtonContainer = styled.div`
    margin-top: 30px;
`;

const Container = styled.div`
    text-align: center;
`;

interface TogglePasswordVisibilityProps {
    handleShowPasswordClick: () => void;
    showPassword: boolean;
}

const TogglePasswordVisibility = ({
    handleShowPasswordClick,
    showPassword,
}: TogglePasswordVisibilityProps): ReactElement => {
    return (
        <InputAdornment position="end">
            <Tooltip title={showPassword ? 'Hide Password' : 'Show Password'}>
                <IconButton onClick={handleShowPasswordClick}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </Tooltip>
        </InputAdornment>
    );
};

const ForgotPasswordComponent = (): ReactElement => {
    return (
        <FormHelperText variant="standard">
            <Link
                component={RouterLink}
                to="/users/login/forgot"
                underline="hover"
            >
                Forgot password?
            </Link>
        </FormHelperText>
    );
};

export declare interface AuthFormProps {
    alternateLinkText: string;
    alternateLinkUrl: string;
    headingText: string;
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
    showForgotPassword: boolean;
    submitButtonText: string;
    signUp?: boolean;
    error?: boolean;
    errorMsg?: string;
    includeNameInput?: boolean;
}

const AuthForm = ({
    alternateLinkText,
    alternateLinkUrl,
    headingText,
    handleSubmit,
    showForgotPassword,
    submitButtonText,
    signUp = false,
    error = false,
    errorMsg = '',
    includeNameInput = false,
}: AuthFormProps): ReactElement => {
    const [fullName, setFullName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const handleNameChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        setFullName(event.target.value);
    };
    const handleEmailChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        setEmail(event.target.value);
    };
    const handlePasswordChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        setPassword(event.target.value);
    };
    const handleShowPasswordClick = (): void => {
        setShowPassword(!showPassword);
    };
    return (
        <Container>
            <FormContainer>
                <FormHeader>
                    <Typography variant="h5" component="h1">
                        {headingText}
                    </Typography>
                    <Typography variant="body1">
                        or{' '}
                        <Link
                            component={RouterLink}
                            to={alternateLinkUrl}
                            underline="hover"
                        >
                            {alternateLinkText}
                        </Link>
                    </Typography>
                </FormHeader>
                <Form onSubmit={handleSubmit}>
                    {includeNameInput && (
                        <FormControl
                            margin="normal"
                            variant="standard"
                            error={error}
                        >
                            <InputLabel htmlFor="full-name">
                                Full Name
                            </InputLabel>
                            <Input
                                id="full-name"
                                name="name"
                                value={fullName}
                                type="text"
                                autoComplete="name"
                                onChange={handleNameChange}
                                sx={{ padding: 0 }}
                                required
                            />
                        </FormControl>
                    )}
                    <FormControl
                        margin="normal"
                        variant="standard"
                        error={error}
                    >
                        <InputLabel htmlFor="email-input">Email</InputLabel>
                        <Input
                            id="email-input"
                            name="email"
                            value={email}
                            type="email"
                            autoComplete="email"
                            onChange={handleEmailChange}
                            sx={{ padding: 0 }}
                            required
                        />
                    </FormControl>
                    <FormControl
                        margin="normal"
                        variant="standard"
                        error={error}
                    >
                        <InputLabel htmlFor="password-input">
                            Password
                        </InputLabel>
                        <Input
                            id="password-input"
                            name="password"
                            value={password}
                            type={showPassword ? 'text' : 'password'}
                            autoComplete={
                                signUp ? 'new-password' : 'current-password'
                            }
                            onChange={handlePasswordChange}
                            required
                            endAdornment={
                                <TogglePasswordVisibility
                                    handleShowPasswordClick={
                                        handleShowPasswordClick
                                    }
                                    showPassword={showPassword}
                                />
                            }
                        />
                        {showForgotPassword && <ForgotPasswordComponent />}
                    </FormControl>
                    {error && (
                        <FormHelperText>
                            <Typography variant="body2" color="error">
                                {errorMsg}
                            </Typography>
                        </FormHelperText>
                    )}
                    <FormButtonContainer>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            {submitButtonText}
                        </Button>
                    </FormButtonContainer>
                </Form>
            </FormContainer>
        </Container>
    );
};

export default AuthForm;
