import React, {ReactElement} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography } from '@mui/material';

const CompanyName = () : ReactElement => {
    return(
        <Link color="inherit" component={RouterLink} to="/" underline="none">
            <Typography variant="h6" color="inherit">Internshipped</Typography>
        </Link>
    );
};

export default CompanyName;
