import React, {ReactElement} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton, Menu, MenuItem, } from '@mui/material';
import { Logout, Person, Settings } from '@mui/icons-material';
import UserAvatar from '../basic/UserAvatar';

export declare interface AuthedComponentsProps {
    fullName: string;
    handleLogout: () => void;
};

const AuthedDesktopComponents = ({fullName, handleLogout} : AuthedComponentsProps):ReactElement => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchorEl);
    const handleIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    return(
        <>
            <IconButton onClick={handleIconClick}>
                <UserAvatar fullName={fullName} />
            </IconButton>
            <Menu 
                open={isOpen} 
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                onClick={handleMenuClose}>
                <MenuItem component={RouterLink} to="/users/profile"><Person sx={{marginRight: 1}} />Profile</MenuItem>
                <MenuItem component={RouterLink} to="/users/settings"><Settings sx={{marginRight: 1}} />Settings</MenuItem>
                <MenuItem onClick={handleLogout}><Logout sx={{marginRight: 1}} />Logout</MenuItem>
            </Menu>
        </>
    );
};

export default AuthedDesktopComponents;
