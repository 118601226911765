import React, {ReactElement} from 'react';
import { Button, Typography } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { Box } from '@mui/system';
import UserAvatar from '../../components/basic/UserAvatar';

export declare interface ProfileInfoProps {
    fullName: string,
};

const ProfileInfoContainer = muiStyled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
});

const ProfileInfo = ({fullName} : ProfileInfoProps):ReactElement => {
    return(
        <ProfileInfoContainer>
            <UserAvatar fullName={fullName} />
            <Typography variant="h5" component="h1">{fullName}</Typography>
            <Button variant="contained" color="primary">Edit Profile</Button>
        </ProfileInfoContainer>
    );
};

export default ProfileInfo;
