import React, {ReactElement} from 'react';
import { AppBar, Toolbar, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import MobileNav from './MobileNav';
import DesktopNav from './DesktopNav';
import { maxWidthMediaQuery, mobileMaxWidth } from '../../constants/MediaQueries';

export declare interface NavbarProps {
    loggedIn: boolean;
    fullName?: string;
    handleLogout: () => void;
};

const Navbar = ({ loggedIn, fullName="", handleLogout } : NavbarProps) : ReactElement => {
    const isMobile = useMediaQuery(maxWidthMediaQuery(mobileMaxWidth));
    return(
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static">
                <Toolbar sx={{backgroundColor: 'Background'}}>
                    {isMobile ? <MobileNav loggedIn={loggedIn} handleLogout={handleLogout} /> : <DesktopNav loggedIn={loggedIn} fullName={fullName} handleLogout={handleLogout} />}
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default Navbar;
