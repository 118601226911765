import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router';
import AuthForm from '../../components/complex/AuthForm';
import { signUpUser } from '../../services/auth';

const Signup = (): ReactElement => {
    const [error, setError] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState('');
    const navigate = useNavigate();
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        setError(false);
        setErrorMsg('');
        const name = event.currentTarget.elements.namedItem(
            'name'
        ) as HTMLInputElement;
        const email = event.currentTarget.elements.namedItem(
            'email'
        ) as HTMLInputElement;
        const password = event.currentTarget.elements.namedItem(
            'password'
        ) as HTMLInputElement;
        signUpUser(name.value, email.value, password.value)
            .then(() => {
                navigate('/');
            })
            .catch((errorMsg) => {
                setError(true);
                setErrorMsg(errorMsg);
            });
    };
    return (
        <AuthForm
            alternateLinkText="sign in"
            alternateLinkUrl="/users/login"
            headingText="Create an Account"
            handleSubmit={handleSubmit}
            showForgotPassword={false}
            submitButtonText="Create Account"
            signUp={true}
            error={error}
            errorMsg={errorMsg}
            includeNameInput={true}
        />
    );
};

export default Signup;
