import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router';
import AuthForm from '../../components/complex/AuthForm';
import { signInUser } from '../../services/auth';

const Login = (): ReactElement => {
    const [error, setError] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState('');
    const navigate = useNavigate();
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        setError(false);
        setErrorMsg('');
        const email = event.currentTarget.elements.namedItem(
            'email'
        ) as HTMLInputElement;
        const password = event.currentTarget.elements.namedItem(
            'password'
        ) as HTMLInputElement;
        signInUser(email.value, password.value)
            .then(() => {
                navigate('/');
            })
            .catch((errorMsg) => {
                setError(true);
                setErrorMsg(errorMsg);
            });
    };
    return (
        <AuthForm
            alternateLinkText="create an account"
            alternateLinkUrl="/users/signup"
            headingText="Sign In"
            handleSubmit={handleSubmit}
            showForgotPassword={true}
            error={error}
            errorMsg={errorMsg}
            submitButtonText="Sign In"
        />
    );
};

export default Login;
